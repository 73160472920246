<template>
    <b-modal 
        modal-class="modal-envio-email"
        v-model="exibe" 
        body-class="app-modal-custom-body" 
        header-class="app-modal-custom-header" 
        footer-class="app-modal-custom-footer" 
        size="lg"
        no-enforce-focus
    >
        <template v-slot:default="{ cancel, ok }">

            <div class="container-fluid h-100">

                <div class="row">
                    <div class="col-12">
                        <h3 class="modal-envio-email-titulo"> Novo Email </h3>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h5> Ticket gerado: {{ ticket }} </h5>
                    </div>
                </div>

                <div class="row pt-1">
                    <div class="col-md-6">
                        <label class="mb-1">Para</label>
                        <input class="modal-envio-email-input" v-model="emailPara">
                    </div>
                    <div class="col-md-6">
                        <label class="mb-1">Cc</label>
                        <input class="modal-envio-email-input" v-model="emailCC">
                    </div>
                </div>

                <div class="row pt-1">
                    <div class="col-12">
                        <label class="mb-1">Assunto</label>
                        <input class="modal-envio-email-input" v-model="assunto">
                    </div>
                </div>

                <div class="row pt-3 pb-2">
                    <div class="col-12">
                        <input-arquivo :placeholder="'Anexar arquivo...'"
                                       @upload="uploadAnexo" />
                    </div>
                </div>

                <div class="row pt-1">
                    <div class="col-12">
                        <label class="mb-1">Mensagem</label>
                        <div class="modal-envio-email-editor-wrapper">
                            <HtmlEditor v-model="mensagem" :dynamicArea="false" />
                        </div>
                    </div>
                </div>

                <div class="row py-2">
                    <div class="col-6">
                        <ul class="modal-envio-email-lista-erros">
                            <li v-for="(erro, i) in erros" :key="i">{{ erro }}</li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <botao-modal-ok class="float-right ml-2"
                                        @click="enviarEmail(ok)">
                            Enviar
                        </botao-modal-ok>
                        <botao-modal-cancel class="float-right ml-2" @click="cancel()"> Fechar </botao-modal-cancel>
                    </div>
                </div>

            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>

    </b-modal>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
	import InputArquivo from '@/components/inputs/InputArquivo.vue';
	import HtmlEditor from "@/components/inputs/HtmlEditor";

export default {
    name: 'ModalEnvioEmail',

    components: {
        BotaoModalOk,
        BotaoModalCancel,
        HtmlEditor,
        InputArquivo
    },

        props: {
            dadosOrcamento: '',
        },

    data: function() {
        return {
            atendimento: null,
            exibe: false,
            erros: [],
            emailPara: '',
            emailCC: '',
            assunto: '',
            mensagem: '',
            anexo: null,
            normal:''
        }
    },
        watch: {
            dadosOrcamento() {
                this.emailPara = this.dadosOrcamento.emailCliente
               // this.mensagem = this.dadosOrcamento.toString();
            }
        },
    computed: {
        ticket() {
            this.normal = (this.atendimento?.CodigoProtocolo || 0) == 0;
            return this.atendimento?.CodigoProtocolo || 0
        }
    },

    methods: {
        show() {
            this.exibe = true
        },
        hide() {
            this.limpa()
            this.exibe = false
        },
        limpa() {
            this.erros = []
            this.atendimento = null
            this.emailPara = ''
            this.emailCC = ''
            this.assunto = ''
            this.mensagem = ''
            this.anexo = null
            this.normal = ''
        },
        uploadAnexo(arquivo) {
            this.anexo = arquivo
        },
        enviarEmail(ok) {
            this.erros = []

            if(!this.emailPara) {
                this.erros.push('Informe o endereço de email para qual deseja enviar a mensagem')
            }
            if(!this.assunto) {
                this.erros.push('Informe o assunto do email')
            }

            if(!this.mensagem) {
                this.erros.push('Escreva uma mensagem')
            }

            if(this.erros.length > 0) {
                return
            }

            const form = {
                atendimento: { ...this.atendimento },
                emailPara: this.emailPara,
                emailCC: this.emailCC,
                assunto: this.assunto,
                mensagem: this.mensagem,
                anexo: this.anexo,
                normal: this.normal
            }

            this.$emit('enviar-email', form)
            ok()
        }
    }
}
</script>

<style>
.modal-envio-email-titulo {
    color: var(--cor-primaria-cliente);
}

.modal-envio-email-input {
    width: 100%;
    border: 1px solid var(--cinza-4);
    background-color: var(--cinza-3);
}

.modal-envio-email-editor-wrapper {
    border: 4px solid var(--cinza-3);
}

.modal-envio-email-lista-erros {
    color: red;
}

</style>