<template>
    <b-modal v-model="exibe"
             body-class="app-modal-custom-body"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             size="xl"
             no-enforce-focus>

        <template v-slot:default="{ cancel, ok }">
            <b-container class="bv-example-row" id="container_orcamento">
                <b-row>
                    <b-col>
                        <span style=" font-size: 15px; font-weight: bold; margin-bottom: 15px; display: block;">Orcamento</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div>
                            <label for="">Solicitação:</label>
                            <select class="form-select select_item_lista modal_orcamento_input" aria-label="Default select example" v-model="situacao_selecionada">
                                <option disabled>Escolha...</option>
                                <option value="nenhum">Nenhum</option>
                                <option value="novo">Novo</option>
                                <option value="portabilidade">Portabilidade</option>
                                <option value="ttpf">TTPF</option>
                                <option value="pj">PJ</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col>
                        <div>
                            <label for="">Tipo:</label>
                            <select class="form-select select_item_lista modal_orcamento_input" aria-label="Default select example" v-model="tipo_selecionado">
                                <option disabled>Escolha...</option>
                                <option value="nenhum">Nenhum</option>
                                <option value="novo">Novo</option>
                                <option value="incremento">Incremento</option>
                                <option value="renovacao">Renovação</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col col lg="1">
                        <div>
                            <label for="">Quantidade:</label><br />
                            <input type="number" class="modal_orcamento_input" id="qnt_prod" v-model="qnt_produto" />
                        </div>
                    </b-col>
                    <b-col>
                        <div>
                            <label for="">Categoria:</label>
                            <select class="form-select select_item_lista modal_orcamento_input" aria-label="Default select example" v-model="categoria_selecionada" @change="categoria_selecionada_function">
                                <option disabled>Escolha...</option>
                                <option :value="item.Id" v-for="item in listaCategorias">{{item.CodigoProduto}}-{{item.Nome}}</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col>
                        <div>
                            <label for="">Produtos:</label>
                            <div>
                                <input list="my-list-id" class="form-select select_item_lista modal_orcamento_input" @change="prod_selecionado_function()" id="testeid" v-model="item">
                                <datalist id="my-list-id">
                                    <option>Escolha...</option>
                                    <option v-for="item in listaProdutosCarregados"
                                            :value="item">
                                    </option>
                                </datalist>
                            </div>
                        </div>
                    </b-col>
                    <b-col>
                        <div>
                            <label for="">Compensação mensal:</label>
                            <select class="form-select select_item_lista modal_orcamento_input" aria-label="Default select example" v-model="mensalidade_selecionada">
                                <option disabled>Escolha...</option>
                                <option :value="item.Value" v-for="item in mensalidade">{{item.Nome}}</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col col lg="1">
                        <div style="float:right">
                            <br />
                            <span id="add_produto" @click="add_produto_lista">+</span>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="listaProdutosMensalArray.length > 0" class="titulo_tabela">
                    <b-col>
                        <b>Compromisso mensal</b>
                    </b-col>
                </b-row>
                <b-row v-if="listaProdutosMensalArray.length > 0">
                    <b-col>
                        <table id="produtos_mensalidade">
                            <thead class="cabecalho_tabela">
                                <tr>
                                    <td>Produto</td>
                                    <td>Quantidade</td>
                                    <td>Valor unitário</td>
                                    <td>Total</td>
                                </tr>
                            </thead>
                            <tr v-for="item in listaProdutosMensalArray">
                                <td>
                                    <span class="excluir_produto" @click="remove_produto_lista(listaProdutosMensalArray,item, 'produto')"><i class="fas fa-trash-alt"></i></span>  {{item.nome}}
                                </td>
                                <td>
                                    <input :id="'qnt_'+item.id" type="number" v-model="quantidade_prod.parent_id[item.id]" style="border: #e8e8e8" />
                                </td>
                                <td>
                                    R$
                                    <input :id="'valor_'+item.id" type="number" v-model="valor_prod.parent_id[item.id]" style="border: #e8e8e8" />
                                    <span class="recalcular_produto" @click="calculadora('produtos',item.id)"><i class="fas fa-sync-alt"></i></span>
                                </td>
                                <td>
                                    R$  <span :id="'total_item_'+item.id">{{total_prod.parent_id[item.id]}}</span>
                                </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
                <b-row v-if="listaProdutosArray.length > 0" class="titulo_tabela">
                    <b-col>
                        <b>Pagamento único</b>
                    </b-col>
                </b-row>
                <b-row v-if="listaProdutosArray.length > 0">
                    <b-col>
                        <table id="produtos_outros">
                            <thead>
                                <tr>
                                    <td>Produto</td>
                                    <td>Quantidade</td>
                                    <td>Valor unitário</td>
                                    <td>Total</td>
                                </tr>
                            </thead>
                            <tr v-for="item in listaProdutosArray">
                                <td>
                                    <span class="excluir_produto" @click="remove_produto_lista(listaProdutosArray,item,'outros')"><i class="fas fa-trash-alt"></i></span>  {{item.nome}}
                                </td>
                                <td>
                                    <input :id="'qnt_'+item.id" type="number" v-model="quantidade_prod.parent_id[item.id]" style="border: #e8e8e8" />
                                </td>
                                <td>
                                    R$
                                    <input :id="'valor_'+item.id" type="number" v-model="valor_prod.parent_id[item.id]" style="border: #e8e8e8" />
                                    <span class="recalcular_produto" @click="calculadora('outros',item.id)"><i class="fas fa-sync-alt"></i></span>
                                </td>
                                <td>
                                    R$  <span :id="'total_item_'+item.id">{{total_prod.parent_id[item.id]}}</span>
                                </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
                <b-row class="linha_subtotal" style="background: #e8e8e8; width: 100%; margin: 10px auto;">
                    <b-col cols="12" md="8">Subtotal</b-col>
                    <b-col cols="6" md="4">R$ {{subtotal_orcamento}}</b-col>
                    <!--<b-col cols="12" md="8">Desconto</b-col>
                    <b-col cols="6" md="4">R$ {{total_desconto}}</b-col>-->
                </b-row>
                <b-row id="titulo_reumo" style="background: #e8e8e8; width: 100%; margin: 10px auto;">
                    <b-col><b>Valor total da negociação</b></b-col>
                    <b-col cols="6" md="4">R$ {{total_orcamento}}</b-col>
                </b-row>



                <b-row style="width:100%; margin:0px auto">
                    <b-row id="rodape_modal_orcamento">

                        <b-col>
                            <div>
                                <label for="">
                                    Usar capa? <i class="far fa-question-circle"
                                                  v-b-tooltip.hover
                                                  title="Caso seleção da opção, será adicionado uma capa com logo pre-escolhido de nome 'logoCapaPDF.png'"
                                                  style="text-align:left"></i>
                                </label>
                                <select class="form-select select_item_lista modal_orcamento_input" aria-label="Default select example" v-model="capa_selecionada">
                                    <option disabled>Escolha...</option>
                                    <option :value="item.Value" v-for="item in capa">{{item.Nome}}</option>
                                </select>
                            </div>
                        </b-col>
                        <b-col>
                            <div>
                                <label for="">Rodape:</label>
                                <select class="form-select select_item_lista modal_orcamento_input" aria-label="Default select example" v-model="rodape_selecionado">
                                    <option disabled>Escolha...</option>
                                    <option :value="item.caminho" v-for="item in listaImagensOrcamento.imagensOrcamento">{{item.nome}}</option>
                                </select>
                            </div>
                        </b-col>
                        <b-col style="max-width: 70%;">
                            <span class="btn_acoes_orcamento" id="btn_exportar_pdf" @click="gerar_pdf_orcamento">Exportar PDF</span>
                        </b-col>
                        <!--<b-col style="max-width: 50%;">
                <label for="">Email:</label>
                <select class="form-select select_item_lista modal_orcamento_input" aria-label="Default select example" v-model="email_selecionado">
                    <option disabled>Escolha...</option>
                    <option :value="item.value" v-for="item in lista_email">{{item.nome}}</option>
                </select>
            </b-col>-->
                        <b-col style="max-width: 70%;">
                            <span class="btn_acoes_orcamento" id="btn_enviar_email_orcamento" @click="enviar_email">Enviar por e-mail</span>
                        </b-col>
                        <b-col>
                            <label for="">Anexos:</label>
                            <div class="mr-5" style="width:91%">
                                <span class="btn_arquivo_orcamento" id="btn_arquivo_orcamento" style="float:left" @click="carregarImagens">Arquivos</span>
                            </div>
                            <!--<label for="">Desconto %</label><br />
                <input class="form-select select_item_lista modal_orcamento_input" id="desconto_input" v-model="valor_desconto" placeholder="0%" disabled />
                <span id="add_desconto" @click="calcula_desconto">Ok</span>-->
                        </b-col>

                    </b-row>
                    <ModalImagensDV ref="modal_imagensDV" :dadosCard="ImagensDV" />
                </b-row>
            </b-container>
            <ModalEnvioEmail ref="modal_envio_email"
                             @enviar-email="enviarArquivoDepoisEmail"
                             :dadosOrcamento="dadosOrcamento" />

        </template>
        
        <template v-slot:modal-footer style="width:94%; margin:0px auto">
            <b-row>
                <b-col style="margin-bottom:25px;">
                   
                    <span style="margin-right: 20px; line-height: 1.2; display: inline-block; " class="btn_acoes_orcamento" id="btn_cancelar_orcamento" @click="fechar_modal">Cancelar</span>
                    <span style="float: right; line-height: 1.3;" class="btn_acoes_orcamento" id="btn_enviar_email_orcamento" @click="salvar_orcamento_db">Salvar</span>
                </b-col>
            </b-row>
        </template>
    </b-modal>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
    import jsPDF from 'jspdf'
    import ModalEnvioEmail from '@/components/modals/ModalEnvioEmail.vue'
    import ModalImagensDV from '@/components/modals/ModalCarregaImagensDV.vue'
    // Services
    import { mapActions } from 'vuex';
    import ProdutosServices from '@/assets/js/services/ProdutosServices';
    import AtendimentoEmailServices from '@/assets/js/services/AtendimentoEmailServices';
    import DiscoVirtualServices from '../../assets/js/services/DiscoVirtualServices';
    export default {
        name: 'ModalOrcamento',
        components: {
            BotaoModalOk,
            BotaoModalCancel, ModalEnvioEmail, ModalImagensDV
        },

        props: {
            itensEdicao: '',
            tipo: String,
            dadosEdicao: '',
            dadosGerais: '',
        },
        data: function () {
            return {
                quantidade_prod: { parent_id: [] },
                valor_prod: { parent_id: [] },
                total_prod: { parent_id: [] },
                listaCategorias: [],
                listaSituacoes: [],
                listaProdutos: [],
                mensalidade: [{ Nome: "Sim", Value: "sim" }, { Nome: "Não", Value: "nao" }],
                capa: [{ Nome: "Sim", Value: "sim" }, { Nome: "Não", Value: "nao" }],
                lista_email: [{ nome: "Padrão", value: "padrao" }, { nome: "Outlook", value: "outlook" }],
                produtoEscolhido: '',
                listaProdutosArray: [],
                listaProdutosMensalArray: [],
                listaProdutosCarregados: [],
                listaImagensOrcamento: [],
                categoria_selecionada: '',
                situacao_selecionada: '',
                tipo_selecionado: '',
                mensalidade_selecionada: '',
                rodape_selecionado: '',
                email_selecionado: '',
                capa_selecionada: '',
                exibeErro: false,
                exibe: false,
                qnt_produto: '',
                subtotal_orcamento: 0.00,
                total_orcamento: 0.00,
                total_desconto: 0.00,
                valor_desconto: "",
                item: "",
                imgt: "",
                imgCapaPdf: "",
                dadosOrcamento: "",
                editar: false,
                dados_pdf: '',
                nome_cliente: '',
                dismissSecs: 3,
                dismissCountDown: 0,
                showDismissibleAlert: false,
                ImagensDV: ''
            }
        },
        watch: {
            dadosEdicao() {
                if (this.dadosEdicao[0].sucesso) {
                    this.carregaEdicaoOrcamento()
                }
            }
        },
        mounted() {
           
           
        },

        methods: {
            ...mapActions({
                setLoadingGlobal: 'setLoadingGlobal',
            }),
            show() {
                this.carregaCategorias();
                //this.carregaSituacaoOrcamento();
                this.carregaImagensOrcamento();
                this.limpa()
                this.exibe = true
                if (!this.data) {
                    return
                }
            },
            fechar_modal() {
                this.limpa()
                this.exibe = false
            },
            limpa() {
                this.listaProdutosArray = [];
                this.listaProdutosMensalArray = [];
            },
            carregarImagens: async function () {
                this.ImagensDV = await DiscoVirtualServices.carregaImagensAtendimentoDV(this.dadosGerais.Atendimento.Id)
                this.$refs['modal_imagensDV'].show()
                //this.$refs.modal_envio_email.show()
                // console.log(this.ImagensDV)
            },
            carregaEdicaoOrcamento: function () {
                for (var i in this.dadosEdicao[0].Produtos) {
                    var prodId = this.dadosEdicao[0].Produtos[i].id;
                    var produtoCarrega = this.dadosEdicao[0].Produtos[i];
                    this.quantidade_prod.parent_id[prodId] = produtoCarrega.qnt;
                    this.valor_prod.parent_id[prodId] = produtoCarrega.valor.toFixed(2);
                    this.total_prod.parent_id[prodId] = (produtoCarrega.qnt * produtoCarrega.valor).toFixed(2);
                    if (this.dadosEdicao[0].Produtos[i].tipo == "mensal") {
                        //Mensal
                        this.listaProdutosMensalArray.push(this.dadosEdicao[0].Produtos[i])
                    } else {
                        //aparelhos/outros
                        this.listaProdutosArray.push(this.dadosEdicao[0].Produtos[i])
                    }
                    this.subtotal_orcamento = 0;
                    for (var i in this.total_prod.parent_id) {
                        this.subtotal_orcamento = (parseFloat(this.subtotal_orcamento) + parseFloat(this.total_prod.parent_id[i])).toFixed(2)
                        this.total_orcamento = this.subtotal_orcamento;
                    }
                }
                this.situacao_selecionada = this.dadosEdicao[0].situacao;
               
                this.tipo_selecionado = this.dadosEdicao[0].tipoSelect;
                this.rodape_selecionado = this.dadosEdicao[0].rodape;
                this.capa_selecionada = this.dadosEdicao[0].capa;
                this.editar = true;

            },

            carregaImagensOrcamento: async function () {
                this.listaImagensOrcamento = await ProdutosServices.carregaImagensOrcamento();

                this.imgt = this.listaImagensOrcamento.logoEmpesa[0]?.caminho
                this.imgCapaPdf = this.listaImagensOrcamento.logoCapa[0]?.caminho
            },
            calculadora: function (origem, itemId) {
                this.total_prod.parent_id[itemId] = (this.quantidade_prod.parent_id[itemId] * this.valor_prod.parent_id[itemId]).toFixed(2);
                $("#total_item_" + itemId).text(this.total_prod.parent_id[itemId])
                this.subtotal_orcamento = 0;
                for (var i in this.total_prod.parent_id) {
                    this.subtotal_orcamento = (parseFloat(this.subtotal_orcamento) + parseFloat(this.total_prod.parent_id[i])).toFixed(2)
                    this.total_orcamento = this.subtotal_orcamento;
                }
            },
            carregaSituacaoOrcamento: async function () {
                this.listaSituacoes = await ProdutosServices.carregaSituacaoOrcamento();
            },
            carregaCategorias: async function () {
                this.listaCategorias = await ProdutosServices.carregaCategoriasProdutos();
            },
            categoria_selecionada_function: async function () {
                this.exibeErro = false;
                this.listaProdutos = await ProdutosServices.carregaProdutosPorCatg(this.categoria_selecionada);
                
                if (this.listaProdutos.length <= 0) {
                    this.exibeErro = true;
                }
                this.listaProdutosCarregados = this.listaProdutos.map(item => (item.CodigoProduto + "-" + item.Nome))
            },
            prod_selecionado_function: function () {


                this.produtoEscolhido = '';
                this.produtoEscolhido = this.listaProdutos.filter(f => f.CodigoProduto == this.item.split('-')[0] && f.Nome == this.item.split('-')[1])[0];
            },
            add_produto_lista: function () {
                const produto = {};


                if (this.produtoEscolhido != '' && this.qnt_produto != '' && this.qnt_produto != 0) {
                    this.quantidade_prod.parent_id[this.produtoEscolhido.Id] = this.qnt_produto;
                    this.valor_prod.parent_id[this.produtoEscolhido.Id] = this.produtoEscolhido.Preco.toFixed(2);
                    this.total_prod.parent_id[this.produtoEscolhido.Id] = (this.qnt_produto * this.produtoEscolhido.Preco).toFixed(2);
                    produto.id = this.produtoEscolhido.Id;
                    produto.nome = this.produtoEscolhido.Nome;
                    produto.qnt = this.qnt_produto;
                    produto.valor = this.produtoEscolhido.Preco.toFixed(2);
                    if (this.mensalidade_selecionada == "sim") {
                        //Mensal
                        this.listaProdutosMensalArray.push(produto)
                    } else {
                        //aparelhos/outros
                        this.listaProdutosArray.push(produto)
                    }
                    this.subtotal_orcamento = (parseFloat(this.subtotal_orcamento) + (parseInt(this.qnt_produto) * parseFloat(this.produtoEscolhido.Preco))).toFixed(2).replace(".", ",");
                    this.total_orcamento = (parseFloat(this.subtotal_orcamento) - parseFloat(this.total_desconto)).toFixed(2).replace(".", ",")
                    this.categoria_selecionada = '';
                    this.item = '';
                    this.qnt_produto = '';
                    this.produtoEscolhido = '';
                }
            },
            remove_produto_lista: function (arr, item, origem) {
                const attr = 'id';
                const value = item.id;
                var i = arr.length;
                while (i--) {
                    if (arr[i] && arr[i].hasOwnProperty(attr) && (arr[i][attr] === value)) {
                        const valor_remover = this.quantidade_prod.parent_id[value] * this.valor_prod.parent_id[value];
                        this.total_prod.parent_id[value] = 0.00;
                        this.subtotal_orcamento = Math.abs((valor_remover - this.subtotal_orcamento)).toFixed(2);
                        this.total_orcamento = (parseFloat(this.subtotal_orcamento) - parseFloat(this.total_desconto)).toFixed(2);
                        arr.splice(i, 1);
                    }
                }
                if (origem == 'produto') {
                    this.listaProdutosMensalArray = arr;
                } else {
                    this.listaProdutosArray = arr;
                }
            },
            calcula_desconto: function () {
                if (this.total_orcamento != 0.00 && this.total_orcamento != '' && this.valor_desconto != 0.00) {
                    this.total_desconto = ((parseFloat(this.subtotal_orcamento.replace('.', '').replace(',', '.')) * (parseFloat(this.valor_desconto) / 100))).toFixed(2);
                    this.total_orcamento = (parseFloat(this.subtotal_orcamento.replace('.', '').replace(',', '.')) - this.total_desconto.replace('.', '').replace(',', '.')).toFixed(2);
                }
            },
            salvar_orcamento_db: async function () {
                
                var orcamento_gravar = [];
                if (this.listaProdutosArray.length > 0) {
                    for (var item in this.listaProdutosArray) {
                        var itemId = this.listaProdutosArray[item].id
                        orcamento_gravar.push({
                            ProdutoId: itemId,
                            AtendimentoId: this.dadosGerais.Atendimento.Id,
                            ClienteId: this.dadosGerais.Atendimento.ClienteId,
                            Nome: this.listaProdutosArray[item].nome,
                            Quantidade: this.quantidade_prod.parent_id[itemId],
                            ValorUnit: this.valor_prod.parent_id[itemId],
                            ValorTotalProd: this.total_prod.parent_id[itemId],
                            ValorTotalOrca: this.total_orcamento,
                            Situacao: this.situacao_selecionada,
                            TipoSelect: this.situacao_selecionado,
                            Rodape: this.rodape_selecionado,
                            Capa: this.capa_selecionada,
                            Tipo: "unico",
                            Editar: this.editar,
                            OrcamentoId: this.dadosEdicao ? this.dadosEdicao[0].orcamentoId : null
                        })
                    }
                }
                if (this.listaProdutosMensalArray.length > 0) {
                    for (var item in this.listaProdutosMensalArray) {
                        var itemId = this.listaProdutosMensalArray[item].id
                        orcamento_gravar.push({
                            ProdutoId: itemId,
                            AtendimentoId: this.dadosGerais.Atendimento.Id,
                            ClienteId: this.dadosGerais.Atendimento.ClienteId,
                            Nome: this.listaProdutosMensalArray[item].nome,
                            Quantidade: this.quantidade_prod.parent_id[itemId],
                            ValorUnit: this.valor_prod.parent_id[itemId],
                            ValorTotalProd: this.total_prod.parent_id[itemId],
                            Situacao: this.situacao_selecionada,
                            TipoSelect: this.tipo_selecionado,
                            ValorTotalOrca: this.total_orcamento,
                            Rodape: this.rodape_selecionado,
                            Capa: this.capa_selecionada,
                            Tipo: "mensal",
                            Editar: this.editar,
                            OrcamentoId: this.dadosEdicao ? this.dadosEdicao[0].orcamentoId : null
                        })
                    }
                }
                this.fechar_modal();
                $("#caixa_carregamento").show();
               this.dados_pdf = await ProdutosServices.salvarOrcamento(orcamento_gravar);
                $("#caixa_carregamento").hide();
                this.nome_cliente = this.dados_pdf.nomeCliente
                this.dadosOrcamento = this.dados_pdf;
                alert("Orçamento salvo com sucesso")
                return this.dados_pdf;
            },
            gerar_pdf_orcamento: async function () {
                let retorno = await this.salvar_orcamento_db();
                var pdf = new jsPDF('p', 'pt', 'letter');
                var container_pdf = document.createElement('div');
                container_pdf.setAttribute('style', 'position:relative;right: 100; top:10px; left:10px; font-size:12px; bottom:0; height:100%; width:600px');
                container_pdf.innerHTML = this.gerarTabelaPdf();
                pdf.html(container_pdf, {
                    callback: function (pdf) {
                        var div = document.createElement('pre');
                        div.innerText = pdf.output();
                        pdf.save("Proposta " + retorno.nomeCliente + ".pdf", div)
                    },
                });

            },
            gerarTabelaPdf: function () {

                const img = new Image();
                var altura = 500;
                img.onload = function () {
                    altura = 750 - this.height;


                    //alert(this.width + 'x' + this.height);
                    //alert(this.width + 'x' + this.height);
                }
                img.src = this.imgCapaPdf;
                var modelo_pdf = "";
                if (this.capa_selecionada == "sim") {

                    modelo_pdf +=
                        "<div id='capa_orcamento' style='height:" + altura + "px;'><img id='img_capa' style=' display:table; margin: 300px auto'img src=" + this.imgt + " />" +
                        "<div style='position:absolute; botton:0; right:15px'>" +
                        "<span style = 'margin-top: 15px'> " + this.dados_pdf.usuarioNome + "</span><br />" +
                        "<span style = 'margin-top: 15px'> " + this.dados_pdf.usuarioEmail + "</span><br />" +
                        "<span style = 'margin-top: 15px'> 1499631619</span><br />" +
                        "</div></div>";
                }
                modelo_pdf += "<img style='width:30%' src=" + this.imgt + " /><br>" +
                    "<span>Olá " + this.nome_cliente + "</span><br />" +
                    "<span style='font-size:10px;'>segue a proposta de nro: " + this.dados_pdf.numeroOrcamento + "</span><br>";

                if (this.listaProdutosMensalArray.length > 0) {
                    modelo_pdf += "<span style = 'margin-top: 40px'>Compromisso mensal</span><br />" +
                        "<table style = 'width: 100%; border: 1px solid #e8e8e8;' > " +
                        "<thead><tr><td style='border: 1px solid #e8e8e8; font-weight:bold;font-size:10px;'>Produto</td>" +
                        "<td style = 'border: 1px solid #e8e8e8;font-weight:bold; font-size:10px;' > Quantidade</td><td style='border: 1px solid #e8e8e8;font-weight:bold;font-size:10px;'>Valor unitário</td><td style='border: 1px solid #ccc;font-weight:bold;font-size:10px;'>Total</td></tr ></thead > ";
                    for (var item in this.listaProdutosMensalArray) {
                        var itemId = this.listaProdutosMensalArray[item].id
                        modelo_pdf += "<tr>" +
                            "<td style='border: 1px solid #e8e8e8; font-size:10px'>" + this.listaProdutosMensalArray[item].nome + "</td>" +
                            "<td style='border: 1px solid #e8e8e8;font-size:10px'>" + this.quantidade_prod.parent_id[itemId] + " </td>" +
                            "<td style='border: 1px solid #e8e8e8; font-size:10px'>R$ " + this.valor_prod.parent_id[itemId].replace(".", ",") + " </td>" +
                            "<td style='border: 1px solid #e8e8e8; font-size:10px'>R$ " + this.total_prod.parent_id[itemId].replace(".", ",") + "</td></tr>";
                    }
                    modelo_pdf += "</table> ";
                }
                if (this.listaProdutosArray.length > 0) {
                    modelo_pdf += "<span style = 'margin-top: 40px'>Pagamento único</span><br />" +
                        "<table style = 'width: 100%; border: 1px solid #e8e8e8;' > " +
                        "<thead><tr><td style='border: 1px solid  #e8e8e8; font-weight:bold;font-size:10px;'>Produto</td>" +
                        "<td style = 'border: 1px solid  #e8e8e8;font-weight:bold; font-size:10px;' > Quantidade</td><td style='border: 1px solid #e8e8e8;font-weight:bold;font-size:10px;'>Valor unitário</td><td style='border: 1px solid #ccc;font-weight:bold;font-size:10px;'>Total</td></tr ></thead > ";
                    for (var item in this.listaProdutosArray) {
                        var itemId = this.listaProdutosArray[item].id
                        modelo_pdf += "<tr>" +
                            "<td style='border: 1px solid #e8e8e8; font-size:10px'>" + this.listaProdutosArray[item].nome + "</td>" +
                            "<td style='border: 1px solid #e8e8e8;font-size:10px'>" + this.quantidade_prod.parent_id[itemId] + " </td>" +
                            "<td style='border: 1px solid #e8e8e8; font-size:10px'>R$ " + this.valor_prod.parent_id[itemId].replace(".", ",") + " </td>" +
                            "<td style='border: 1px solid #e8e8e8; font-size:10px'>R$ " + this.total_prod.parent_id[itemId].replace(".", ",") + "</td></tr>";
                    }
                    modelo_pdf += "</table> ";
                }
                modelo_pdf += "<span style='margin-top:25px;display:block'>Valor total da negociação: R$ " + this.total_orcamento + "</span> ";
                if (this.rodape_selecionado != "") {
                    modelo_pdf += "<img style='margin-top:50px;width: 100%;display:block' src=" + this.rodape_selecionado + " /><br>";
                }
                modelo_pdf += "<span style='margin-top:10px; display:block'>Caso tenha alguma dúvida por favor entre em contato</span><br/> ";
                modelo_pdf += "<span style='margin-top:10px; display:block'>Essa proposta é válida por 3 dias</span> ";
                return modelo_pdf;
            },
            enviar_email: async function () {
                var container_email = document.createElement('div');
                container_email.setAttribute('style', 'right: 100; top:10px; left:10px; font-size:12px; bottom:0; height:100%; width:600px');
                var r = this.gerarTabelaPdf();
                container_email.innerHTML = r.toString();
                //container_email.append(r);

                var $html = $(container_email);
                var str = $html.prop('outerHTML');
                if (this.email_selecionado == "outlook") {
                    var emlContent = "data:message/rfc822 eml;charset=utf-8,";
                    emlContent += 'To: aaa\n';
                    emlContent += 'Subject: ddd\n';
                    emlContent += 'X-Unsent: 1' + '\n';
                    emlContent += 'Content-Type: text/html' + '\n';
                    emlContent += '' + '\n';
                    emlContent += container_email.innerHTML;
                    var ar = this.code();
                    var encodedUri = encodeURI(emlContent); //encode spaces etc like a url
                    var a = document.createElement('a'); //make a link in document
                    var linkText = document.createTextNode(ar);
                    a.appendChild(linkText);
                    a.href = encodedUri;
                    a.id = ar;
                    a.download = ar + '.eml';
                    a.style = "display:none;"; //hidden link
                    document.body.appendChild(a);
                    document.getElementById(ar).click(); //click the link

                } else {
                    this.showToast()
                    await this.salvar_orcamento_db();
                    this.$refs['modal_envio_email'].show()
                    //this.$refs.modal_envio_email.show()
                    //this.salvar_orcamento_db()
                }

            },
            showToast() {
                // Use a shorter name for this.$createElement
                const h = this.$createElement

                // Create the message
                const vNodesMsg = h(
                    'p', { class: ['text-center', 'mb-0'] },
                    [
                        h('b-spinner', { props: { type: 'grow', small: true } }),
                        ' Carregando Email '
                    ]
                )
                // Create the title
                const vNodesTitle = h(
                    'div',
                    { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
                    [
                        h('strong', { class: 'mr-2' }, 'Aguarde!!'),
                    ]
                )
                // Pass the VNodes as an array for message and title
                this.$bvToast.toast([vNodesMsg], {
                    title: [vNodesTitle],
                    solid: true,
                    variant: 'info',
                    toaster: 'b-toaster-top-right',
                    'auto-hide-delay': 1000
                })
            },

            code: function () {
                return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            },
            async enviarArquivoDepoisEmail(form) {
                this.setLoadingGlobal(true)
                let nomeArquivo = ''
                if (form.anexo) {
                    nomeArquivo = await this.enviarArquivo(form)
                    if (!nomeArquivo) {
                        this.setLoadingGlobal(false)
                        return
                    }
                    form.nomeArquivo = nomeArquivo
                }
                await this.enviarEmail(form)
                this.setLoadingGlobal(false)
            },
            async enviarEmail(form) {
                try {
                    await AtendimentoEmailServices.enviarEmail(
                        form.atendimento.Id,
                        this.canalEmailId,
                        form.nomeArquivo || '',
                        form.assunto,
                        form.mensagem,
                        form.emailPara,
                        form.emailCC,
                        form.normal
                    )
                    this.$bvToast.toast('Email enviado com sucesso', {
                        title: `Aviso`,
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        variant: 'success',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    })
                    
                } catch (e) {
                    console.log(e.message)
                    //this.mostrarErro(e.message)
                }
            },
        }
    }
</script>

<style scoped>
    #container_orcamento, #rodape_modal_orcamento {
        font-size: 12px;
    }

    #erro_busca_produtos {
        color: red;
        font-size: 12px;
    }

    #qnt_prod {
        width: 100%;
    }

    #titulo_reumo {
        margin-top: 20px;
    }

    #add_desconto {
        background-color: #39d325;
        cursor: pointer;
        width: 38px;
        text-align: center;
        margin-top: 5px;
        color: #fff;
        padding: 8px;
    }

    #add_produto {
        color: #fff;
        background-color: #39d325;
        cursor: pointer;
        font-size: 21px;
        width: 38px;
        display: table;
        text-align: center;
        margin-top: 5px;
    }

    #resumo_orcamento {
        background: #f7f7f7;
        width: 73%;
        margin: 0px 12px;
    }

    #desconto_input {
        width: 50%;
    }

    #produtos_mensalidade {
        width: 100%;
        border: 1px solid #e8e8e8;
    }

        #produtos_mensalidade > th, td {
            border: 1px solid #e8e8e8;
        }

    #produtos_outros {
        width: 100%;
        border: 1px solid #e8e8e8;
    }

        #produtos_outros > th, td {
            border: 1px solid #e8e8e8;
        }

    #rodape_modal_orcamento {
        margin-top: 15px;
        width: 100%;
    }

    .tooltip-inner {
        text-align: left;
    }

    .cabecalho_tabela {
        font-weight: bold
    }

    .linha_subtotal {
        margin-top: 50px;
    }

    .titulo_tabela {
        margin-top: 25px;
    }

    .modal_orcamento_input {
        height: 30px;
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }

    .excluir_produto {
        padding: 5px;
        margin: 3px 10px 0px 0px;
        background: red;
        color: #FFF;
        cursor: pointer;
        display: inline-flex;
    }

    .recalcular_produto {
        padding: 5px;
        margin: 3px 10px 0px 0px;
        background: #39d325;
        color: #FFF;
        cursor: pointer;
        display: inline-flex;
    }

    .btn_acoes_orcamento {
        cursor: pointer;
        width: 118px;
        text-align: center;
        margin-top: 5px;
        padding: 7px;
        position: relative;
        bottom: -14px;
        line-height: 4.5;
    }

    #btn_enviar_email_orcamento {
        background-color: #39d325;
        color: #fff;
    }

    .btn_arquivo_orcamento {
     
        cursor: pointer;
        width: 118px;
        text-align: left;
        padding: -1px;
        position: relative;
        line-height: 2.5;
    }

    #btn_exportar_pdf, #btn_arquivo_orcamento, #btn_cancelar_orcamento {
        border-bottom: 2px solid;
    }
</style>