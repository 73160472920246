<template>

    <div class="card-task bg-white shadow rounded px-3 pt-3 pb-5_2 border border-white" :class="stateClass" :id="'cardId' + atendimentoId">

        <div class="flex justify-between">
            <span class="card_kanban_protocolo">#{{task.Protocolo}}</span>
            <i class="fas fa-info-circle fa-2x conversa_funil" v-on:click="abrirConversa"></i>
        </div>
        <div class="flex justify-between items-center mb-2">
            <span class="card_funil_nome_cliente">{{task.ClienteNome == null ? 'Não Informado' : task.ClienteNome}}</span>
          
            <span class="card_funil_nome_usuario">
                Criado por <b>{{task.AtendenteNome == null ? 'Não Informado' : task.AtendenteNome}}</b>
            </span>
            <span class="card_funil_data_inicio">Iniciado em  {{task.Inclusao}}</span>
            <span v-if="task.DataRetorno != null" class="card_funil_data_alterada">Alterado em {{task.DataRetorno}}</span>
            <div>
                <select class="select_kanban" v-model="tabulacaoSelecionada" @change="trocar_tabulacao($event)">
                    <option value="0" disabled>Escolher coluna</option>
                    <option :value="item" v-for="(item, i) in selectOptionCard" v-if="itemTabulacao != item.AtabulacaoItemId">{{item.Titulo}} </option>
                </select>
            </div>
        </div>
        <div v-if="task.KanbanPerfil != 2" style="display:contents">
            <div v-if="rhAtivo" style="display:contents">
                <span class="card_kanban_orcamento"
                      @click="abrirModalCliente(task)"
                      :id="'btn-cliente' + task.Id"
                      v-b-tooltip.hover="{ variant: 'success' }"
                      title="Cliente">
                    <i class="fas fa-user-tag mr-2" style="width:5px"></i>
                </span>
            </div>
            <div v-else style="display:contents">
                <span class="card_kanban_orcamento"
                      v-b-toggle="task.Id"
                      :id="'btn-cliente' + atendimentoId"
                      v-b-tooltip.hover="{ variant: 'success' }"
                      title="Cliente">
                    <i class="fas fa-user-tag mr-2"></i>
                </span>
            </div>

            <b-collapse :id="task.Id" class="mt-2">
                <b-card class="h-auto" v-if="task.ClienteId == '00000000-0000-0000-0000-000000000000'">
                    <span class="btn btn-success my-1" @click="abrirModalCliente(task)">Novo Cliente</span>
                    <span class="btn btn-success my-1" @click="abrirModalCliente(task, 2)">Existente</span>
                </b-card>

                <b-card class="h-auto" v-else>
                    <span class="btn btn-success" @click="abrirModalCliente(task)">Editar</span>
                </b-card>
            </b-collapse>
        </div>
        <span v-if="task.KanbanPerfil == 2" class="card_kanban_orcamento ajuste_espaco"
              @click="abrirModalVagaKanban(task)"
              :id="'btn-vaga' + task.Id"
              v-b-tooltip.hover="{ variant: 'success' }"
              title="Vaga">
            <i class="fas fa-file-alt"></i>
        </span>
        <span v-if="task.KanbanPerfil == 2 || task.KanbanPerfil == 3  && task.ClienteId != '00000000-0000-0000-0000-000000000000'" class="card_kanban_Ticket ajuste_espaco"
              @click="ticketFinanceiro(task)"
              :id="'btn-cria_ticket' + task.Id"
              v-b-tooltip.hover="{ variant: 'success' }"
              title="Finalizar vaga">
            <i class="fas fa-clipboard-check"></i>
        </span>
        <div v-if="task.KanbanPerfil == 3" style="float:left">
            <span v-if="(orcamento != 'remover' && orcamento != null) && task.ClienteId != '00000000-0000-0000-0000-000000000000'" class="card_kanban_orcamento card_btn_situacao" v-on:click="abrirOrcamento(task)" :id="'btn-orcamento' + task.Id" v-b-tooltip.hover="{ variant: 'success' }"
                  title="Orçamento">
                <i class="fas fa-dollar-sign"></i>
            </span>

            <label :id="'btn-upload'+task.Id" :for="'arquivo_kanban' + task.Id" v-if="(orcamento != 'remover' && orcamento != null) && task.ClienteId != '00000000-0000-0000-0000-000000000000'" class="card_kanban_orcamento card_btn_situacao" style="display: initial;" v-b-tooltip.hover="{ variant: 'success' }"
                   title="Upload"><i class="fas fa-file-upload"></i></label>
        </div>

        <ModalOrcamento ref="modal_orcamento" :dadosGerais="dadosGerais" :dadosEdicao="dadosEdicao" />

        <!--<input type="file">-->
        <b-form-file v-model="file" ref="inputArquivo" class="mb-2" :id="'arquivo_kanban' + task.Id" style="display:none" @change="uploadArquivoKanban"></b-form-file>
        <slot></slot>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex'
    import axios from 'axios'
    import Badge from "./Badge.vue";
    import ModalOrcamento from '@/components/orcamento/ModalOrcamento.vue';
    import InputSelect from '@/components/inputs/InputSelect.vue';
    import ProdutosServices from '@/assets/js/services/ProdutosServices';
    import DiscoVirtualServices from '@/assets/js/services/DiscoVirtualServices';

    export default {
        data: function () {
            return {
                dadosGerais: '',
                dadosEdicao: '',
                file: null,
                tabulacaoSelecionada: 0,
                protocoloCard: '',
                stateClass: "loading",
                task: {
                    IdAtendimentoTabulacao:'',
                    Atendimento: {
                        IdCabecalho: null,
                        ClienteNome: '',
                        NomeCandidatoVaga: null,
                        KanbanPerfil: 0,
                        NomeCandidatoVaga: null,
                        VagaNome: null,
                        AtendenteNome: null,
                        Inclusao: null,
                        DataRetorno: null,
                        ClienteId: null,
                        colunaAtualId: null

                    },
                    SelectOption: {
                        AtabulacaoItemId: '',
                        Kanban: '',
                        Titulo: ''
                    },
                },
               
            }
        },
        components: {
            Badge,
            ModalOrcamento,
            
            InputSelect,
        },
        props: {
            rhAtivo:false,
            columns: null,
            column: null,
            atendimentoId: '',
            itemTabulacao: '',
            guid: '',
            movendo: false,
            moveAtendimento:null,
            moveTabId: null,
            usuarioId: null,
            selectOptionCard: [],
            dadosCard:null

        },
        watch: {
            movendo() {
                return this.movendo
            },
            columns() {
                return this.columns;
            },
        },
        created() {
            this.carregaCardKanban(this.atendimentoId, this.itemTabulacao)
        },
        //computed: {
        //    ...mapGetters({
        //        ModulosDisponiveis: 'modulos_integracoes/modulosItegracoes',
        //    }),
        //    orcamento: function () {
        //        if (this.ModulosDisponiveis) {
        //            //orcamento.parametros[0].Valor
        //            return this.ModulosDisponiveis.find(res => res?.tipo === "ORCAMENTO")?.parametros[0].Valor;
        //        }
        //    },
        //    badgeColor() {
        //        const mappings = {
        //            Design: "purple",
        //            "Feature Request": "teal",
        //            Backend: "blue",
        //            QA: "green",
        //            default: "teal"
        //        };
        //        return mappings[this.task.type] || mappings.default;
        //    },
        //},
        methods: {
            carregaCardKanban: function (idAtendimento, idItem) {

                this.task = this.dadosCard;
                this.stateClass = "";

                //idAtendimento = this.moveAtendimento == null ? idAtendimento : this.moveAtendimento?.Id;
                //let atendimentotabid = this.moveTabId == null ? this.column.AtendimentosCardsIds.find(f => f.AtendimentoId == idAtendimento).AtendimentoTabulacaoId : this.moveTabId;
                //if (this.movendo == false) {
                //    this.carregaCardKanbanContinue(idAtendimento, idItem)
                //} else {
                //    const dados = {
                //       "AtendimentoTabulacaoId": atendimentotabid,
                //        "AtendimentoId": idAtendimento,
                //        "TabulcaoItemId": idItem,
                //    }
                //    axios.post('/api/tabulacao/modificaKanban', dados).then(() => {
                //        this.carregaCardKanbanContinue(idAtendimento, idItem)
                //    })
                //}
            },
            carregaCardKanbanContinue(idAtendimento, idItem) {
                this.task = this.dadosCard;
                this.stateClass = "";
                
            },
            abrirConversa: function () {
                this.$emit("abrirConversa", this.task.Id, this.task.Protocolo, this.task.ClienteId, this.task.UsuarioLogado, this.task)
            },
            abrirModalCliente: function () {
                let dados = {
                    task: this.task.IdAtendimentoTabulacao,
                    move: this.task,
                    Coluna: this.column,
                    TabulacaoId: this.column.TabulacaoId, 
                };
                this.stateClass = "loading";
                this.$emit("abrirModalCliente", this.task, dados)
                this.stateClass = "";
            },
           
            uploadArquivoKanban: function (event) {
                let formData = new FormData();
                formData.append('file', event.target.files[0])
                DiscoVirtualServices.salvarArquivoDiscoVirtual(this.task.Id, formData)
                ///   this.$store.dispatch('servicosApi/uploadArquivoDiscoVirtual', formData)

            },
            emitirInfo: function () {
                this.$emit("emitirInfo", {
                    AtendimentoId: this.task.Id,
                    Protocolo: this.task.Protocolo,
                    ClienteId: this.task.ClienteId,
                    Observacoes: this.task.Observacoes,
                })
            },
            trocar_tabulacao: function () {
                if (this.tabulacaoSelecionada) {
                    let dados = {
                        tabItem: this.tabulacaoSelecionada.AtabulacaoItemId,
                        IdAtendimentoTabulacao: this.task.IdAtendimentoTabulacao,
                        move: this.task,
                        //coluna: this.tabulacaoSelecionada.Kanban ? this.columns.find(f => f.IdCabecalho == this.tabulacaoSelecionada.AtabulacaoItemId).Consulta : '',
                        //oldColuna: this.columns.find(f => f.IdCabecalho == this.task.Atendimento.IdCabecalho).Consulta,
                        oldColuna: this.column,
                        kanban: this.tabulacaoSelecionada.Kanban
                    };
                    this.$emit("selectMudaColunaKanban", dados)
                }
            },
            abrirModalVagaKanban: function () {
                this.$emit("abrirModalVagaKanban", this.task);
            },
            ticketFinanceiro: function () {
                this.$emit("ticketFinanceiro", this.task);
            },
            atualizarDados: function (card) {
                this.stateClass = "loading";
                let dados = {
                    task: this.task.IdAtendimentoTabulacao,
                    move: this.task,
                    Coluna: this.column,
                };
                this.$emit("atualizarDados", dados);
                this.stateClass = "";


                //this.dadosGerais = card
                //$("#caixa_carregamento").show();
                ////this.$refs['modal_atividade'].show()

                //$("#caixa_carregamento").hide();
            },
       
        }
    };
</script>

<style scoped>
    #full-screen-modal > .modal-dialog {
        max-width: 100%;
        display: contents
    }
    .minhaclass {
            max-width: 100%;
            display: inline;
        }
    .card-task {
        position: relative;
        cursor: grab;
        margin: 15px 0;
    }

        .card-task.loading:after {
            content: "";
            position: absolute;
            width: 0;
            height: 3px;
            background-color: #5656e7;
            left: 0;
            bottom: 0;
            right: 100%;
            animation: infinite-loading-bar 1.5s linear infinite;
        }

    .card_kanban_protocolo {
        color: #5656e7;
        font-size: 12px;
        font-weight: bold;
    }

    .card_funil_nome_cliente {
        display: block;
        font-size: 14px;
        font-weight: bold;
        color: #5656e7;
    }

    .card_funil_nome_candidato {
        display: block;
        font-size: 11px;
        font-weight: bold;
        color: #5656e7;
    }

    .card_funil_nome_vaga {
        display: block;
        font-size: 11px;
        font-weight: bold;
        color: #5656e7;
    }

    .card_funil_data_inicio {
        display: block;
        font-size: 11px;
        color: #0fa590;
    }

    .card_funil_nome_usuario {
        display: block;
        font-size: 12px;
        color: green;
    }

    .card_funil_data_alterada {
        display: block;
        font-size: 11px;
        color: #c127f3
    }

    .conversa_funil {
        float: right;
        cursor: pointer;
        color: #5656e7;
    }

    .card_kanban_orcamento {
        font-size: 12px;
        color: #FFF;
        background-color: #19b1bd;
        padding: 3px 9px;
        cursor: pointer;
        margin-left: 7px
    }

    .card_kanban_Ticket {
        font-size: 12px;
        color: #FFF;
        background-color: #ffb93c;
        padding: 3px 9px;
        cursor: pointer;
        margin-left: 7px
    }

    .pb-5_2 {
        padding-bottom: 0.5rem;
    }

    .select_kanban {
        font-size: 11px;
        height: 16px;
        border: 0px;
        width: 100%;
        background-color: #e8e8e8;
    }

        .select_kanban:focus {
            outline: 0;
        }

    @keyframes infinite-loading-bar {
        0% {
            left: 0%;
            right: 100%;
            width: 0%;
        }

        20% {
            left: 0%;
            right: 60%;
            width: 40%;
        }

        80% {
            right: 0%;
            left: 60%;
            width: 40%;
        }

        100% {
            left: 100%;
            right: 0%;
            width: 0%;
        }
    }
</style>