<template>
    <b-modal v-model="exibe"
             body-class="app-modal-custom-body"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             size="sm"
             no-enforce-focus>
        <template v-slot:default="{ cancel, ok }">
            <b-container class="bv-example-row" id="container_atividade">
                <b-row>
                    <b-col>
                        <div>
                            <label for="numero_atividade" style="display:contents">{{dadosCard.length == 0 ? "Não possui Arquivos" :"Arquivos: " }}</label><br />
                            <b-row v-for="item in dadosCard" style="border: 1px solid #ccc; margin-bottom: 5px; ">
                                <b-col>{{item.ImagemNome.substring(0,12)}}...</b-col>
                                <b-col><span class="btn_download" @click="baixar_imagem(item.Caminho, item.ImagemNome)"><i class="fas fa-file-download"></i></span></b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </template>
        <template v-slot:modal-footer>
            <div class="mr-5">
            </div>
        </template>
    </b-modal>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
    
    export default {
        name: 'ModalCarregaImagensDV',
        components: {
            BotaoModalOk,
            BotaoModalCancel,
        },
        props: {
            dadosCard: ''
        },
        data: function () {
            return {
                codAtividade: '',
                exibe: false,
                retorno: ''
            }
        },
        watch: {
            dadosCard() {
                this.carrega();
                return this.dadosCard
            }
        },
        mounted() {

        },
        methods: {

            show() {
                this.limpa()
                this.exibe = true
                if (!this.data) {
                    return
                }
            },
            limpa() {
                this.codAtividade = '';
            },
            carrega: function () {
               
            },
            baixar_imagem: function (imagem, nome) {
                const element = document.createElement('a')
                element.href = imagem
                element.setAttribute('download', nome)
                document.body.appendChild(element)
                element.click()
                document.body.removeChild(element);
            }
        }
    }
</script>

<style scoped>

    .btn_download {
        padding: 0px 10px;
        background-color: #ccc;
        cursor: pointer;
        text-align: center;
        float:right;
        display: inherit;
    }


    .btn_salvar_atividade {
        cursor: pointer;
        width: 58px;
        text-align: center;
        padding: 3px;
        position: relative;
        background-color: #39d325;
        color: #fff;
        float: right;
    }
</style>